import NodeRSA from 'node-rsa'

const publicKey = '-----BEGIN PUBLIC KEY-----\n' +
'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsOeG/X0OVb3+zISptpns\n' +
'CFM1QkYmxCw6r4WKYkXVXXKIcsij9APctZKfJ3lLxsWV+XJ4sEi3wXwcKPBFfNL8\n' +
'uGPg5VJo8BAxmE34hN7R4Mav6kuUtCytJfq4I3l5b6el8IqvOLCVF0rOIwjM4DGy\n' +
'n/XA/4Jnkjx5M7JyeNj+6jZWaDNw191+CVyR4oyhc9YKW8pHCvVlJ0bMlNCwxbUT\n' +
'a3piMYJyl9y0FrSfDJKdGqX7LVaFLizzAPlmWHRG5YY+cSiLe15T6QcK/Pmc0UIk\n' +
'frT29MKojlTgEp+AoGYnjOKBewzac2tsFkDOgcMVFcEYju/lvlXk+wHhLXCQvrWW\n' +
'WwIDAQAB\n' +
'-----END PUBLIC KEY-----'

export function RSAEncrypt (secret) {
  const cipher = new NodeRSA(publicKey)
  return cipher.encrypt(secret, 'base64')
}
